import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Carousel from 'react-3d-carousels'

import theme from '../theme'

const CarouselWrapper = styled.div`
  width: 300px;
  height: 450px; 
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const CarouselItemWrapper = styled.div`
  width: 300px; 
  height: 400px;
  text-align: center;
`

const CarouselButton = styled.button`
  background: transparent;
  color: ${theme.palette.primary.main};
  font-weight: bold;
  border: none;
  width: 100px;
  height: 50px;
  cursor: pointer;
`

type Props = {
    title: string,
    frontItem: {
        imgSrc: string,
        label: string | null
    },
    backItem: {
        imgSrc: string,
        label: string
    } | null
}

export default class Carousel3D extends PureComponent<Props> {
    private carousel : Carousel = null

    render() {
        return(
            <CarouselWrapper>
              <p>{this.props.title}</p>
              <Carousel 
                ref={carousel => (this.carousel = carousel)}
                width={300}
                direction="horizontal"
                effect="3d"
                index={0}
                onClick={() => ({})}>
                <CarouselItemWrapper>
                    <img src={this.props.frontItem.imgSrc}/>
                    <p>{this.props.frontItem.label}</p>
                    {
                        this.props.backItem ? (
                            <CarouselButton onClick={()=>{ this.carousel.next() }}>Flip</CarouselButton>
                        ) : null 
                    }
                </CarouselItemWrapper>
                {
                    this.props.backItem ? (
                        <CarouselItemWrapper>
                            <img src={this.props.backItem.imgSrc}/>
                            <p>{this.props.backItem.label}</p>
                            <CarouselButton onClick={()=>{ this.carousel.prev() }}>Flip</CarouselButton>
                        </CarouselItemWrapper>
                    ) : null
                }
              </Carousel>
            </CarouselWrapper>
        )
    }
}