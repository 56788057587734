import React, { PureComponent, SFC, SyntheticEvent, ImgHTMLAttributes, ReactNode } from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { everyFrame, styler } from 'popmotion'
import Carousel from 'react-3d-carousels'

import Modal from '../components/modal'
import Section, {SubSection, Header, Divider} from '../components/section/section'
import { WindowLocation } from '@reach/router'
import Carousel3D from '../components/carousel3D'

import theme from '../theme'
import Logo from '../images/strange-music-logo.svg'

const ImageGrid = styled.div`
  display: grid;
  grid-template-areas: 'a1 a2 a2' 'b1 b1 b2' 'c1 c2 c2';
  grid-gap: 1rem;

  @media(max-width: 600px) {
    grid-template-areas: 'a1' 'a2' 'b1' 'b2' 'c1' 'c2';
  }
`

export const FirstSectionWrapper = styled.div`
  max-height: calc(100vw * 9/16);
  margin-top: -100px;
  box-shadow: 0px 2px 10px 0px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: ${theme.palette.primary.main};

  @media(max-width: 768px) {
    margin-top: -56px;
  }
`

export const FirstSection = styled.div`
  width: 100%;
  max-height: calc(90vw * 9/16);
  position: relative;
  overflow: hidden;
`

export const FirstSectionTextWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`

export const FirstSectionText = styled.h1`
  font-size: 5rem;
  font-weight: bold;
  color: white;
  margin: 10px 0 10px 10px;

  @media(max-width: 768px) {
    font-size: 1.5rem; 
  }
`

const UnorderedList = styled.ul`
  list-style: none;
  margin: 0;
`

const ListItem = styled.li`
  ::before {
    content: "•";
    color: white;
    margin-right: 10px;
  }

  :last-child {
    margin: 0;
  }
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around
`

const FlexItem = styled.div`
  margin: 10px;
  min-width: 250px;
`

const SVGWrapper = styled(SVG)`
  & {
    svg {
      width: 75px;
      height: 75px;
      fill: white;

      @media(max-width: 768px) {
        width: 50px;
        height: 50px;
      }
    }
  }
`

const GraphicDesignContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 500px;
  min-width: 320px;
  max-width: 500px;
  margin: 0 auto;

  @media(max-width: 768px) {
    min-width: 100%;
  }
`

const CarouselWrapper = styled.div`
  width: 300px;
  height: 450px; 
  display: flex;
  flex-direction: column;
  text-align: center;
`

const CarouselItemWrapper = styled.div`
  width: 300px; 
  height: 400px;
  text-align: center;
`

const CarouselButton = styled.button`
  background: transparent;
  color: ${theme.palette.primary.main};
  font-weight: bold;
  border: none;
  width: 100px;
  height: 50px;
  cursor: pointer;
`

interface IndexPageProps {
  location: WindowLocation,
  children: ReactNode[]
}

interface IndexPageState {
  isMobile: boolean,
  toggledImage: string | undefined
}

export default class Index extends PureComponent<IndexPageProps, IndexPageState> {
  private carousel : Carousel = null

  constructor(props: IndexPageProps) {
    super(props)

    this.toggleImage = this.toggleImage.bind(this)

    this.state = {
      isMobile: false,
      toggledImage: undefined
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.resize()

    const container = document.querySelector('.graphicContainer')
    const stylers = Array.from(container.childNodes).map(styler)
    
    everyFrame()
      .start((timestamp) => stylers.map((thisStyler, i) => {
        thisStyler.set('y', 5 * Math.sin(0.004 * timestamp + (i * 0.5)))
      }))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize() {
    if (window.innerWidth <= 768) {
      this.setState({
        isMobile: true
      })
    } else {
      this.setState({
        isMobile: false
      })
    }
  }

  toggleImage = (event: SyntheticEvent<ImgHTMLAttributes<HTMLImageElement>>) : void => {
    this.setState({
      toggledImage: event.currentTarget ? event.currentTarget.src : undefined
    })
  }

  render() {
    const modalizedImage = this.state.toggledImage ? (
      <Modal handleClose={this.toggleImage}>
        <img 
          src={this.state.toggledImage} 
          alt="Toggled"
          style={{
            margin: '0',
            maxHeight: '90vh',
            maxWidth: '99vw', 
            objectFit: 'contain',
            padding: '40px'
          }}/>
      </Modal>
    ) : null

    return (
      <Layout location={this.props.location}>
        <FirstSectionWrapper>
          <FirstSection>
            <img src={require('../images/aboutUs.jpg')} alt="first image"/>
            <FirstSectionTextWrapper>
              <FirstSectionText>STRANGE</FirstSectionText>
              <FirstSectionText>WORLD</FirstSectionText>
              <FirstSectionText>MERCHANDISING</FirstSectionText>
            </FirstSectionTextWrapper>
          </FirstSection>
        </FirstSectionWrapper>
        <Section
          width="100%"
          margin="0"
          backgroundColor="white"
          flexDirection="column">
          <Section
            margin="0 auto"
            flexDirection="column">
            <Header>Our Work</Header>
            <Divider themeColor={theme.palette.primary.main} />
          </Section>
          <Section 
            width="90%"
            margin="0 auto"
            flexWrap="wrap"
            justifyContent="center">
            <Carousel3D  
              title="6 Color Print Short Sleeve T-Shirt"
              frontItem={{
                imgSrc: require('../images/merch/merch11Front.png'), 
                label: 'Front'
              }}
              backItem={{
                imgSrc: require('../images/merch/merch11Back.png'),
                label: 'Back' 
              }}/>
            <Carousel3D  
              title="5 Color Print Long Sleeve Shirt"
              frontItem={{
                imgSrc: require('../images/merch/merch12Front.png'), 
                label: 'Front'
              }}
              backItem={{
                imgSrc: require('../images/merch/merch12Back.png'),
                label: 'Back' 
              }}/>
            <Carousel3D  
              title="7 Color Print Short Sleeve T-Shirt"
              frontItem={{
                imgSrc: require('../images/merch/merch13Front.png'), 
                label: 'Front'
              }}
              backItem={{
                imgSrc: require('../images/merch/merch13Back.png'),
                label: 'Back' 
              }}/>
            <Carousel3D  
              title="5 Color Print Short Sleeve T-Shirt"
              frontItem={{
                imgSrc: require('../images/merch/merch7Front.png'), 
                label: 'Front'
              }}
              backItem={{
                imgSrc: require('../images/merch/merch7Back.png'),
                label: 'Back' 
              }}/>
            <Carousel3D  
              title="5 Color Print Short Sleeve T-Shirt"
              frontItem={{
                imgSrc: require('../images/merch/merch6Front.png'), 
                label: 'Front'
              }}
              backItem={{
                imgSrc: require('../images/merch/merch6Back.png'),
                label: 'Back' 
              }}/>
            <Carousel3D  
              title="3 Color Print Neoprene Can Koozie"
              frontItem={{
                imgSrc: require('../images/merch/merch1Front.png'), 
                label: 'Front'
              }}
              backItem={{
                imgSrc: require('../images/merch/merch1Back.png'),
                label: 'Back' 
              }}/>
            <Carousel3D  
              title="5 Color Print Long Sleeve Shirt"
              frontItem={{
                imgSrc: require('../images/merch/merch5Front.png'), 
                label: 'Front'
              }}
              backItem={{
                imgSrc: require('../images/merch/merch5Back.png'),
                label: 'Back' 
              }}/>
            <Carousel3D  
              title="4 Color Print Sweatpants"
              frontItem={{
                imgSrc: require('../images/merch/merch2.png'), 
                label: null
              }}
              backItem={null}/>
            <Carousel3D  
              title="3 Color Print Hooded Sweatshirt"
              frontItem={{
                imgSrc: require('../images/merch/merch4Front.png'), 
                label: 'Front'
              }}
              backItem={{
                imgSrc: require('../images/merch/merch4Back.png'),
                label: 'Back' 
              }}/>
            <Carousel3D  
              title="7 Color Print Hooded Sweatshirt"
              frontItem={{
                imgSrc: require('../images/merch/merch3Front.png'), 
                label: 'Front'
              }}
              backItem={{
                imgSrc: require('../images/merch/merch3Back.png'),
                label: 'Back' 
              }}/>
          </Section>
        </Section>
        <Section width="100%" margin="0" backgroundColor="whitesmoke">
          <SubSection>
            <Header>Equipment & Services</Header>
            <Divider themeColor={theme.palette.primary.main}/>
          </SubSection>
        </Section>
        <Section width="100%" margin="0" flexDirection="column">
          <Service backgroundImage={require('../images/screens.jpg')}>
            <ServiceTitle>Screens</ServiceTitle>
            <ServiceText>Meshes ranging from 65 to 336</ServiceText>
          </Service>
          <Service backgroundImage={require('../images/pressCloseup.jpg')}>
            <ServiceTitle>M&R Challenger III Press</ServiceTitle>
            <UnorderedList>
              <ListItem><ServiceText>Automatic Press</ServiceText></ListItem>
              <ListItem><ServiceText>14 Pallet Arms</ServiceText></ListItem>
              <ListItem><ServiceText>Averages 300 Shirts per Hour</ServiceText></ListItem>
              <ListItem><ServiceText>Programmable Speed and Pressure</ServiceText></ListItem>
              <ListItem><ServiceText>Up to 9 Colors Printed at Once</ServiceText></ListItem>
            </UnorderedList>
          </Service>
          <Service backgroundImage={require('../images/dryer.jpg')}>
            <ServiceTitle>M&R Sprint 3000</ServiceTitle>
            <UnorderedList>
              <ListItem><ServiceText>20 Foot Gas Dryer</ServiceText></ListItem>
              <ListItem><ServiceText>Adjustable Temperature & Speed</ServiceText></ListItem>
            </UnorderedList>
          </Service>
          <Service backgroundImage={require('../images/backroom.jpg')}>
            <FlexContainer>
              <FlexItem>
                <ServiceTitle>M&R I-Image</ServiceTitle>
                <UnorderedList>
                  <ListItem><ServiceText>Programmable Image Placement</ServiceText></ListItem>
                  <ListItem><ServiceText>Direct to Screen Image Print</ServiceText></ListItem>
                </UnorderedList>
              </FlexItem>
              <FlexItem>
                <ServiceTitle>M&R Starlight</ServiceTitle>
                <UnorderedList>
                  <ListItem><ServiceText>Exposes Screens Using LEDs</ServiceText></ListItem>
                  <ListItem><ServiceText>Programmable Exposure Time</ServiceText></ListItem>
                </UnorderedList>
              </FlexItem>
              <FlexItem>
                <ServiceTitle>M&R Eco-Rinse</ServiceTitle>
                <UnorderedList>
                  <ListItem><ServiceText>Programmable Pressure & Speed</ServiceText></ListItem>
                </UnorderedList>
              </FlexItem>
              <FlexItem>
                <ServiceTitle>M&R Uni-Kote</ServiceTitle>
                <UnorderedList>
                  <ListItem><ServiceText>Quick Drying Emulsion</ServiceText></ListItem>
                  <ListItem><ServiceText>Programmable Pressure & Speed</ServiceText></ListItem>
                  <ListItem><ServiceText>Consistent Coat Every Time</ServiceText></ListItem>
                </UnorderedList>
              </FlexItem>
            </FlexContainer>
          </Service>
        </Section>
        <Section 
          width="100%" 
          margin="0"
          flexDirection="column"
          padding="0 0 20px 0"
          position="relative" 
          background="linear-gradient(to bottom, #42a5f5 0%,#77d1fb 100%)">
          <SubSection>
            <Header style={{ color: 'white' }}>Graphic Design</Header>
            <Divider themeColor="white" />
            <GraphicDesignContainer className="graphicContainer">
              <GraphicDesignItem icon="brush" title="Artwork Design" top={20} />
              <GraphicDesignItem icon="broken_image" title="Artwork Correction" top={100} right={20} />
              <GraphicDesignItem icon="color_lens" title="Color Correction" bottom={100} right={20} />
              <GraphicDesignItem icon="colorize" title="Color Separation" bottom={20} />
              <GraphicDesignItem icon="check" title="Proofing" bottom={100} left={20} />
              <GraphicDesignItem icon="build" title="Screen Print Setup" top={100} left={20} />
              <SVGWrapper id="nav-logo" src={Logo} />
            </GraphicDesignContainer>
          </SubSection>
        </Section>
        {modalizedImage}
      </Layout>
    )
  }
}

const ServiceContainer = styled.div<{backgroundImage?: string}>`
  width: 100%;
  background-image: ${props => props.backgroundImage ? `url(${props.backgroundImage})` : null};
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
`

const ServiceContainerInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 250px;
  background-color: rgba(0,0,0,0.44);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ServiceContainerTextWrapper = styled.div`
  padding: 20px;
  background: rgba(0,0,0,0.4);
  border-radius: 3px;
  box-shadow: -1px 1px 1px 0px;
`

const ServiceTitle = styled.p`
  font-weight: 500;
  font-size: 1.5rem;
  color: white;
`

const ServiceText = styled.p`
  font-weight: 500;
  font-size: 1rem;
  color: white;
  display: inline;
  margin: 0;
`

const Service = ({ backgroundImage, children } : { backgroundImage: string, children: any }) => (
  <ServiceContainer backgroundImage={backgroundImage}>
    <ServiceContainerInnerWrapper>
      <ServiceContainerTextWrapper>
        {children}
      </ServiceContainerTextWrapper>
    </ServiceContainerInnerWrapper>
  </ServiceContainer>
)

const GraphicDesignItemContainer = styled.div<{top?: number, right?: number, bottom?: number, left?: number}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 76px;
  position: absolute;
  top: ${props => props.top ? props.top+'px' : 'unset' };
  right: ${props => props.right ? props.right+'px' : 'unset' };
  bottom: ${props => props.bottom ? props.bottom+'px' : 'unset' };
  left: ${props => props.left ? props.left+'px' : 'unset' };

  @media(max-width: 500px) {
    top: ${props => props.top ? props.top + 25+'px' : 'unset'};
    bottom: ${props => props.bottom ? props.bottom + 25+'px' : 'unset'};
  }
`

const GraphicDesignCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border-radius: 38px;
  background: white;
  box-shadow: 0px 2px 10px -2px black; 

  @media(max-width: 768px) {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
`

const GraphicDesignIcon = styled.i`
  color: ${theme.palette.primary.light}
`

const GraphicDesignTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: white;
  margin: 10px 0 0 0;
  text-align: center;

  @media(max-width: 768px) {
    font-size: 12px;
  }
`

const GraphicDesignItem = ({ icon, title, top, right, bottom, left } : { icon: string, title: string, top?: number, right?: number, bottom?: number, left?: number}) => (
  <GraphicDesignItemContainer top={top} right={right} bottom={bottom} left={left}>
    <GraphicDesignCircle>
      <GraphicDesignIcon className="material-icons">{icon}</GraphicDesignIcon>
    </GraphicDesignCircle>
    <GraphicDesignTitle>
      {title}
    </GraphicDesignTitle>
  </GraphicDesignItemContainer>
)